<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.name" placeholder="请输入流水号/标题" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="请选择类型" filterable clearable>
						<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getWallets({ ...params, page: 1})">查询</el-button>
					<!-- <el-button type="primary" @click="onExport">导出选中</el-button> -->
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="wallet_table" class="scroll-wrapper absolute" height="100%" :data="wallets" :size="theme.size">
				<el-table-column fixed="left" type="selection" width="42"></el-table-column>
				<el-table-column fixed="left" prop="no" label="流水号" width="200"></el-table-column>
				<el-table-column prop="wallet.user.name" label="用户">
					<template slot-scope="scope">{{scope.row.wallet.user.name}}<template v-if="!admin_user.shop_id">@{{scope.row.wallet.shop ? scope.row.wallet.shop.name : theme.group}}</template></template>
				</el-table-column>
				<el-table-column prop="title" label="标题" width="280"></el-table-column>
				<el-table-column prop="type_name" label="类型"></el-table-column>
				<el-table-column prop="type_change" label="收支"></el-table-column>
				<el-table-column prop="balance" label="结余">
					<template slot-scope="scope">{{scope.row.balance/100}}</template>
				</el-table-column>
				<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
					<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
				</el-table-column>
			</el-table>
			<main-footer>
				<el-pagination
					slot="footer"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getWallets({ ...params, page: 1, perPage: v }) }"
					@current-change="(v) => { getWallets({ ...params, page: v }) }"
					background>
				</el-pagination>
			</main-footer>
		</main-table>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			onExport () {
				const { selection } = this.$refs.wallet_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				this.$confirm('此操作将导出' + selection.length + '条内容, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var file = "余额明细导出" + this.$moment().format('YYYYMMDDHHmmss');
					var header = [['ID', '流水号', '用户', '标题', '类型', '收支', '金额', '结余', '更新时间', '创建时间']]
					var filter = ['id', 'no', 'user_name', 'title', 'type_name', 'type_change', 'change', 'balance', 'updated_at', 'created_at']
					this.$utils.handleDownload(header, filter, selection, file, "余额明细")
				});
			},
			async getWallets (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_WALLETS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.wallets = result.data;
				result.shops && (this.shops = result.shops);
				result.types && (this.types = result.types);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page),
					page: parseInt(result.current_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				types: [],
				wallets: [],
				params: {
					type: '',
					perPage: 10
				}
			}
		},
		created () {
			this.getWallets(this.params, true);
		}
	};
</script>